// you can import these packages anywhere
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/nextjs";

export const initializeLogRocket = () => {
  LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID || "");
  // plugins should also only be initialized when in the browser
  setupLogRocketReact(LogRocket);
  console.log("logrocket initialized");
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
};
