//Sentry is initialized through the wizard
import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";

export const sendSentryError = (
  name: string,
  error: any,
  extraInfo?: Record<string, any>
) => {
  Sentry.captureException(error, (scope) => {
    scope.setTransactionName(name);
    extraInfo && scope.setExtras(extraInfo);
    return scope;
  });
};

export const sendSentryErrorTag = ({
  error,
  category = "support",
  action,
  support = "register_dna_kit",
}: {
  error: ApolloError;
  category?: string;
  support?: string;
  action?: string;
}) => {
  Sentry.captureException(error, {
    tags: {
      category,
      support,
      action,
    },
  });
};

export const sendSentryMessage = (name: string) => {
  Sentry.captureMessage(name);
};
