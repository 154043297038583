import React, { createContext, useReducer } from "react";

export enum UIReducerTypes {
  HideNav = "SHOW_NAV",
  ShowNav = "HIDE_NAV",
  HideNavMenu = "SHOW_NAV_MENU",
  ShowNavMenu = "HIDE_NAV_MENU",
  ShowCart = "SHOW_CART",
  HideCart = "HIDE_CART",
  SetNavContents = "SET_NAV_CONTENT",
}

type UIStateType = {
  nav: boolean;
  navMenu: boolean;
  isMobile: boolean;
  cart: boolean;
  content: string;
};

const initialState = {
  nav: true,
  navMenu: false,
  isMobile: false,
  cart: false,
  content: "Learn",
};

const UIReducer = (
  state: UIStateType,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case UIReducerTypes.ShowNav:
      return { ...state, nav: true };
    case UIReducerTypes.HideNav:
      return { ...state, nav: false };
    case UIReducerTypes.ShowNavMenu:
      return { ...state, navMenu: true, nav: true };
    case UIReducerTypes.HideNavMenu:
      return { ...state, navMenu: false };
    case UIReducerTypes.ShowCart:
      return { ...state, cart: true, nav: false };
    case UIReducerTypes.HideCart:
      return { ...state, cart: false, nav: true };
    case UIReducerTypes.SetNavContents:
      return { ...state, content: payload };
    default:
      return state;
  }
};

export const UIContext = createContext<{
  state: UIStateType;
  dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

//@ts-ignore
export const UIContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UIReducer, initialState);

  return (
    <UIContext.Provider value={{ state, dispatch }}>
      {children}
    </UIContext.Provider>
  );
};
