import React, { createContext, useEffect, useReducer, useState } from "react";
import { gql } from "@apollo/client";
import { useApolloClient, useLazyQuery } from "@apollo/client/react";
import { logout, parseAuthJWT } from "../lib/auth0/auth";
import { segmentIdentify } from "@/utils/analytics";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/nextjs";
import { getCookie } from "cookies-next";
import { useRouter } from "next/router";

/*
  unused because there is an useEffect in /_app that does pretty much the same thing 
  and this is a lot for what is required by the app at the moment. May be useful in the future to easily pass down User 
  without constantly refetching etc. but currently the app pulls from cached data so this is not an issue.
*/

const GET_USER = gql`
  query User($auth0UserId: String!) {
    User(where: { auth0UserId: { _eq: $auth0UserId } }) {
      id
      birthday
      gender
      email
      firstName
      lastName
      cuid
      shopifyCustomerId
    }
  }
`;

const GET_SHIPPING_ADDRESS = gql`
  query fetchUserShippingAddress($userId: String!) {
    fetchUserShippingAddress(userId: $userId) {
      shipping_address {
        address1
        address2
        city
        country
        country_code
        province
        province_code
        zip
      }
    }
  }
`;

const UPDATE_USER_CUID = gql`
  mutation update_User($userId: String!, $afWebUserId: String!) {
    update_User(where: { id: { _eq: $userId } }, _set: { cuid: $afWebUserId }) {
      returning {
        id
        birthday
        gender
        email
        firstName
        lastName
        auth0UserId
        cuid
      }
    }
  }
`;

export enum UserReducerTypes {
  LogIn = "LOG_IN",
  LogOut = "LOG_OUT",
  FetchUser = "FETCH_USER",
  SetShippingAddress = "SET_SHIPPING_ADDRESS",
}

interface UserShippingAddress {
  zip?: string;
  city?: string;
  country?: string;
  address1?: string;
  address2?: string;
  province?: string;
  country_code?: string;
  province_code?: string;
}

type UserType = {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  gender?: string;
  shippingAddress: UserShippingAddress;
};

type UserStateType = {
  User: UserType;
};

const initialState = {
  User:
    typeof window !== "undefined"
      ? JSON.parse(String(getCookie("shop_cart") || "{}"))
      : {},
};

const UserReducer = (
  state: UserStateType,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case UserReducerTypes.LogIn:
      return { ...state, User: payload };
    case UserReducerTypes.LogOut:
      return { ...state };
    case UserReducerTypes.FetchUser:
      console.log("wtf", payload);
      return { ...state, User: payload };
    case UserReducerTypes.SetShippingAddress:
      return {
        ...state,
        User: {
          ...state.User,
          shippingAddress: payload,
        },
      };
    default:
      return state;
  }
};

export const UserContext = createContext<{
  state: UserStateType;
  dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

export const UserContextProvider = ({ children }: any) => {
  const router = useRouter();
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const apolloClient = useApolloClient();

  const auth0UserId = parseAuthJWT("id");
  const [getUser] = useLazyQuery(GET_USER, {
    variables: { auth0UserId: auth0UserId },
    context: { clientName: "rapptr" },
    onCompleted: ({ User }) => {
      console.log("user found", User);
      getShippingAddress();
      dispatch({
        type: UserReducerTypes.FetchUser,
        payload: User[0],
      });
    },
    onError: (error) => {
      console.error("user not found", error);
    },
  });

  const [getShippingAddress] = useLazyQuery(GET_SHIPPING_ADDRESS, {
    context: { clientName: "rapptr" },
    onCompleted: ({ fetchUserShippingAddress }) => {
      if (fetchUserShippingAddress?.shipping_address) {
        dispatch({
          type: UserReducerTypes.SetShippingAddress,
          payload: fetchUserShippingAddress.shipping_address,
        });
      }
    },
    onError: (error) => {
      console.error("Error fetching shipping address", error);
    },
  });

  useEffect(() => {
    console.log("context auth0userId", auth0UserId);
    if (typeof window !== undefined && auth0UserId) {
      getUser();
    }
  }, [auth0UserId]);

  const setUser = (response: any) => {
    const afUserId = getCookie("afUserId");
    if (response?.data?.User?.length > 0) {
      const user = response?.data?.User[0];
      // https://segment.com/docs/connections/spec/identify/#traits
      segmentIdentify({
        id: user?.id,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        birthday: user?.birthday,
        gender: user?.gender,
        "x-hasura-role": parseAuthJWT("role"),
      });
      LogRocket.identify(user?.id, {
        email: user?.email,
        name: user?.lastName
          ? user?.firstName
            ? user?.firstName.concat(" ", user?.lastName)
            : user?.lastName
          : user?.firstName
            ? user?.firstName
            : undefined,
      });
      Sentry.setUser({
        id: user?.id,
        email: user?.email,
      });
      Sentry.setContext("profile", {
        ...user,
      });
      // if user's afUserId is !== to their user.cuid update it here
      if (user.cuid !== afUserId) {
        // mutation hook is causing compilation failures so im using the client
        apolloClient
          .mutate({
            mutation: UPDATE_USER_CUID,
            variables: {
              userId: user.id,
              afWebUserId: afUserId,
            },
            context: { clientName: "rapptr" },
          })
          .then((response: any) => console.log(response))
          .catch((err: any) => {
            console.log(err);
          });
      }
    } else {
      console.log("invalid auth token, redirecting to login");
      logout();
      router.push({ pathname: "/login" });
    }
  };

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
