import { getCookie, deleteCookie } from "cookies-next";
import jwt_decode from "jwt-decode";
import * as Sentry from "@sentry/nextjs";
import LogRocket from "logrocket";
import { resetSegment } from "@/utils/analytics";

export const parseAuthJWT = (value?: "role" | "id") => {
  const cookie = getCookie("auth");
  const parsedCookie = !!cookie ? JSON.parse(cookie as string) : undefined;
  let decodedToken: any;
  try {
    decodedToken = jwt_decode(parsedCookie?.access_token);
    // console.log(decodedToken?.["https://hasura.io/jwt/claims"], "decodedToken");
    switch (value) {
      case "role":
        return decodedToken?.["https://hasura.io/jwt/claims"]?.[
          "x-hasura-role"
        ];
      case "id":
        return decodedToken?.["https://hasura.io/jwt/claims"]?.[
          "x-hasura-user-id"
        ];
      default:
        return decodedToken?.["https://hasura.io/jwt/claims"];
    }
  } catch {}
};

export const getAuthAccessToken = () => {
  const cookie = getCookie("auth");
  const parsedCookie = !!cookie ? JSON.parse(cookie as string) : undefined;
  return parsedCookie?.access_token;
};

export const logout = () => {
  localStorage.removeItem("kitId");
  localStorage.removeItem("activation_code");
  Sentry.setUser(null);
  LogRocket.identify("");
  resetSegment();
  deleteCookie("auth");
};
