import { gql } from "@apollo/client";

/* mainly used for product related queries*/
export const PRODUCT = gql`
  fragment PRODUCT on Product {
    id
    title
    handle
    metafield(namespace: "product.metafields", key: "descriptors.subtitle") {
      value
      type
    }
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    seo {
      title
      description
    }

    media(first: 5) {
      edges {
        node {
          ... on MediaImage {
            id
            image {
              id
              altText
              url
            }
          }
          ... on Video {
            id
            alt
            sources {
              url
            }
            previewImage {
              altText
              url
            }
          }
          ... on ExternalVideo {
            id
            embedUrl
            host
          }
        }
      }
    }
    variants(first: 5) {
      edges {
        node {
          id
          title
          sku
          price {
            amount
            currencyCode
          }
          sellingPlanAllocations(first: 1) {
            edges {
              node {
                sellingPlan {
                  id
                  name
                  options {
                    name
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

//------------------------
/* mainly used for cart related queries */
export const PRODUCTVARIANT = gql`
  fragment PRODUCTVARIANT on ProductVariant {
    id
    title
    sku
    price {
      amount
      currencyCode
    }
    product {
      id
      handle
      metafield(namespace: "Products", key: "descriptors.subtitle") {
        value
        type
      }
      title
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
    image {
      id
      url
    }
  }
`;

export const CART = gql`
  ${PRODUCTVARIANT}
  fragment CART on Cart {
    id
    checkoutUrl
    estimatedCost {
      subtotalAmount {
        amount
        currencyCode
      }
    }
    lines(first: 5) {
      edges {
        node {
          id
          quantity
          merchandise {
            ...PRODUCTVARIANT
          }
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;

//---------------------------
