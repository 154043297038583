import { useEffect } from "react";
import { getCookie, setCookie } from "cookies-next";
import { useRouter } from "next/router";

const useCachedQueryParam = (paramName: string): string | null => {
  const { query } = useRouter();
  const queryParam = query[paramName];

  useEffect(() => {
    if (queryParam) {
      setCookie(paramName, queryParam, { maxAge: 60 * 60 * 24 });
    }
  }, [queryParam, paramName]);

  const getParamFromStorage = () => getCookie(paramName)?.toString() ?? null;

  return getParamFromStorage();
};

export default useCachedQueryParam;
